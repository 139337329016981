var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loader
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-3", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-36 fw-600 white-color my-2 if-tablet",
                          },
                          [
                            _vm._v(
                              "Внесистемная закупка № " +
                                _vm._s(_vm.purchase.reg_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.purchase
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("text-header", [_vm._v("Информация о заказчике")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.customer.name,
                          compact: "",
                          label: "Название",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.customer.inn,
                          compact: "",
                          label: "ИНН",
                        },
                      }),
                      _vm.purchase.customer.kpp
                        ? _c("text-row", {
                            attrs: {
                              value: _vm.purchase.customer.kpp,
                              compact: "",
                              label: "КПП",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.customer.ogrn
                        ? _c("text-row", {
                            attrs: {
                              value: _vm.purchase.customer.ogrn,
                              compact: "",
                              label: "ОГРН",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.customer.address
                        ? _c("text-row", {
                            attrs: {
                              value: _vm.purchase.customer.address,
                              compact: "",
                              label: "Почтовый адрес",
                            },
                          })
                        : _vm._e(),
                      _c("text-header", [_vm._v("Информация о закупке")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.order_type_name,
                          compact: "",
                          label: "Тип закупки",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.external_purchase_category_name,
                          compact: "",
                          label: "Категория закупки",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.purchase_name,
                          compact: "",
                          label: "Предмет закупки",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.purchase_object,
                          compact: "",
                          label: "Объект закупки",
                        },
                      }),
                      _vm.purchase.failed_purchase_id
                        ? _c("text-row-link", {
                            attrs: {
                              "router-link": {
                                name: "purchases.grouped.item.view",
                                params: {
                                  group: "fl44",
                                  id: _vm.purchase.failed_purchase_id,
                                },
                              },
                              value: _vm.purchase.failed_purchase_reg_number,
                              compact: "",
                              label: "Номер несостоявшейся закупки",
                            },
                          })
                        : _vm._e(),
                      _c("text-row-price", {
                        attrs: {
                          value: _vm.purchase.start_price,
                          compact: "",
                          label: "Общая стоимость",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: {
                          value: _vm.purchase.planned_contract_date,
                          compact: "",
                          label: "Дата заключения договора",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: {
                          value: _vm.purchase.planned_end_date,
                          compact: "",
                          label: "Дата исполнения договора",
                        },
                      }),
                      _c("text-header", [_vm._v("Спецификация")]),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticStyle: { width: "60px" } }, [
                                _vm._v("№"),
                              ]),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "400px" } },
                                [_vm._v("Наименование товара (работ, услуг)")]
                              ),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v("Количество"),
                              ]),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v("Ед.изм"),
                              ]),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v("Цена за ед., руб."),
                              ]),
                              _c("th", { staticStyle: { width: "120px" } }, [
                                _vm._v("Стоимость, руб."),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.purchase.items, function (item, index) {
                              return _c(
                                "fragment",
                                _vm._b(
                                  { key: item.id },
                                  "fragment",
                                  item,
                                  false
                                ),
                                [
                                  _c(
                                    "tr",
                                    {
                                      class: _vm.visible[index]
                                        ? "border-bottom-0"
                                        : "",
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(index + 1))]),
                                      _c(
                                        "td",
                                        [
                                          item.ktru
                                            ? [
                                                _c("action-button-small", {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName: "v-b-toggle",
                                                      value:
                                                        "collapse-" + index,
                                                      expression:
                                                        "'collapse-' + index",
                                                    },
                                                  ],
                                                  attrs: {
                                                    icon: !_vm.visible[index]
                                                      ? "eye"
                                                      : "eye-slash",
                                                    title:
                                                      (!_vm.visible[index]
                                                        ? "Показать"
                                                        : "Скрыть") +
                                                      " характеристики",
                                                  },
                                                }),
                                                _c("b-collapse", {
                                                  staticClass: "d-none",
                                                  attrs: {
                                                    id: "collapse-" + index,
                                                  },
                                                  model: {
                                                    value: _vm.visible[index],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.visible,
                                                        index,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "visible[index]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.count))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          item.okei
                                            ? [
                                                _vm._v(
                                                  _vm._s(item.okei.local_symbol)
                                                ),
                                              ]
                                            : [_vm._v("—")],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$formatPrice(item.price))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$formatPrice(
                                                item.count * item.price
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.visible[index],
                                          expression: "visible[index]",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "align-text-top",
                                          attrs: { colspan: "6" },
                                        },
                                        [
                                          item.ktru
                                            ? [
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v("Рег.номер ТРУ:"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.ktru.reg_number
                                                      )
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v("Группа ТРУ:"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.ktru
                                                          .ktru_group_name
                                                      )
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v("Наименование:"),
                                                  ]),
                                                  _vm._v(
                                                    " " + _vm._s(item.ktru.name)
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _c("b", [_vm._v("Тип:")]),
                                                  _vm._v(
                                                    " " + _vm._s(item.ktru.type)
                                                  ),
                                                ]),
                                                item.okpd
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v("Код ОКПД2:"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.okpd
                                                              ? item.okpd.code
                                                              : "—"
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.description
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v("Описание:"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.ktru
                                                              .description
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.specs &&
                                                item.ktru.specs.length > 0
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Характеристики:"
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.specs &&
                                                item.ktru.specs.length > 0
                                                  ? _c(
                                                      "ul",
                                                      _vm._l(
                                                        item.ktru.specs,
                                                        function (
                                                          spec,
                                                          specIndex
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key:
                                                                index +
                                                                "_spec_" +
                                                                specIndex,
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    spec.name
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " - " +
                                                                  _vm._s(
                                                                    spec.value
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : [_vm._v("—")],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]),
                      this.hasLimits
                        ? _c(
                            "div",
                            { staticClass: "my-2 atmo-page-content" },
                            [
                              _c("KBKLimitsList.view", {
                                attrs: { limits: _vm.$data.limits },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("text-header", { attrs: { id: "supplier-info" } }, [
                        _vm._v("Поставщик"),
                      ]),
                      !_vm.purchase.is_private_provider
                        ? [
                            _c("text-row", {
                              attrs: {
                                value: _vm.purchase.supplier.full_name,
                                compact: "",
                                label: "Название",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                value: _vm.purchase.supplier.inn,
                                compact: "",
                                label: "ИНН",
                              },
                            }),
                            _vm.purchase.supplier.kpp
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.purchase.supplier.kpp,
                                    compact: "",
                                    label: "КПП",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier.ogrn
                              ? _c("text-row", {
                                  attrs: {
                                    value: _vm.purchase.supplier.ogrn,
                                    compact: "",
                                    label: "ОГРН / ОГРНИП",
                                  },
                                })
                              : _vm._e(),
                            _c("text-row", {
                              attrs: {
                                value: _vm.purchase.supplier.address,
                                compact: "",
                                label: "Фактический адрес",
                              },
                            }),
                          ]
                        : [
                            _c("text-row", {
                              attrs: {
                                label: "Название",
                                value: "Физическое лицо",
                                compact: "",
                              },
                            }),
                            _vm.isPurchaseCreator()
                              ? [
                                  _c("text-row", {
                                    attrs: {
                                      value:
                                        _vm.purchase.private_provider_data.inn,
                                      compact: "",
                                      label: "ИНН",
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      value:
                                        _vm.purchase.private_provider_data.name,
                                      compact: "",
                                      label: "ФИО",
                                    },
                                  }),
                                  _c("text-row", {
                                    attrs: {
                                      value:
                                        _vm.purchase.private_provider_data
                                          .address,
                                      compact: "",
                                      label: "Адрес регистрации",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                      _c("text-header", { attrs: { id: "contract" } }, [
                        _vm._v("Информация о договоре"),
                      ]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.status.title,
                          compact: "",
                          label: "Статус договора",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          compact: "",
                          label: "Номер договора в системе учета заказчика",
                        },
                        model: {
                          value: _vm.purchase.customer_contract_number,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "customer_contract_number",
                              $$v
                            )
                          },
                          expression: "purchase.customer_contract_number",
                        },
                      }),
                      _vm.purchase.main_file &&
                      (!_vm.purchase.is_private_provider ||
                        (_vm.purchase.is_private_provider &&
                          _vm.isPurchaseCreator()))
                        ? _c("text-row-raw", { attrs: { label: "Договор" } }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "fs-14 col-form-label d-inline-block",
                                attrs: {
                                  href: _vm.$getAttachmentDownloadLink(
                                    _vm.purchase.main_file.id
                                  ),
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(_vm.purchase.main_file.name))]
                            ),
                          ])
                        : _vm._e(),
                      _vm.purchase.supplier_accounting
                        ? [
                            _c("text-header", [
                              _vm._v("Банковские реквизиты поставщика"),
                            ]),
                            _c("text-row", {
                              attrs: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_type
                                    .title,
                                compact: "",
                                label: "Вид обслуживающей организации",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "БИК банка" },
                              model: {
                                value: _vm.purchase.supplier_accounting.bik,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bik",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.bik",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Название банка" },
                              model: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bank_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.supplier_accounting.bank_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Город банка" },
                              model: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bank_city",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.supplier_accounting.bank_city",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Кор. счет" },
                              model: {
                                value: _vm.purchase.supplier_accounting.ks,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "ks",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.ks",
                              },
                            }),
                            _c("text-row", {
                              attrs: { compact: "", label: "Расчетный счет" },
                              model: {
                                value: _vm.purchase.supplier_accounting.rs,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "rs",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.rs",
                              },
                            }),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: { compact: "", label: "Лицевой счет" },
                                  model: {
                                    value: _vm.purchase.supplier_accounting.ls,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "ls",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.ls",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: {
                                    compact: "",
                                    label: "ИНН финансового органа",
                                  },
                                  model: {
                                    value:
                                      _vm.purchase.supplier_accounting.fo_inn,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "fo_inn",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.fo_inn",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: {
                                    compact: "",
                                    label:
                                      "Полное наименование финансового органа",
                                  },
                                  model: {
                                    value:
                                      _vm.purchase.supplier_accounting.fo_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "fo_name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.fo_name",
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "text-header",
                        { attrs: { id: "contract-fulfillment" } },
                        [_vm._v("Исполнение договора")]
                      ),
                      _vm.isPurchaseCreator()
                        ? _c(
                            "div",
                            { staticClass: "atmo-content-group" },
                            [
                              _c(
                                "form-row-single-select",
                                {
                                  attrs: {
                                    options: _vm.dictionaries.purchaseStatuses,
                                    "label-field": "title",
                                    label: "Изменить статус закупки",
                                    "content-cols-lg": 8,
                                    "content-cols-sm": 9,
                                  },
                                  model: {
                                    value: _vm.purchaseStatus,
                                    callback: function ($$v) {
                                      _vm.purchaseStatus = $$v
                                    },
                                    expression: "purchaseStatus",
                                  },
                                },
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        show: _vm.isDataSending,
                                        opacity: "0.6",
                                        rounded: "",
                                        "spinner-small": "",
                                        "spinner-variant": "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase ml-3",
                                          attrs: {
                                            variant: "custom-green",
                                            disabled:
                                              _vm.purchaseStatus ===
                                              _vm.purchase.status.id,
                                          },
                                          on: { click: _vm.changeStatus },
                                        },
                                        [_vm._v("Изменить статус")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.purchase.is_private_provider ||
                      (_vm.purchase.is_private_provider &&
                        _vm.isPurchaseCreator())
                        ? _c(
                            "div",
                            { staticClass: "atmo-content-group" },
                            [
                              _c("form-row-upload-multiple-documents", {
                                attrs: {
                                  "is-loading": _vm.isWaiting,
                                  v: _vm.$v.executionDocuments,
                                  "header-class": "fs-14 ml-1",
                                  title: "Документы об исполнении",
                                },
                                on: {
                                  add: _vm.onAddFile,
                                  remove: _vm.onRemoveFile,
                                },
                                model: {
                                  value: _vm.executionDocuments,
                                  callback: function ($$v) {
                                    _vm.executionDocuments = $$v
                                  },
                                  expression: "executionDocuments",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }