var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("organizations-list-header"),
              _c("organizations-list-filter", {
                attrs: { form: _vm.filter },
                on: {
                  applyFilters: _vm.applyFilters,
                  clearForm: _vm.clearFilter,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          !_vm.loading && !_vm.organizations.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _c(
            "b-container",
            [
              _vm.organizations && _vm.meta.total > 0 && _vm.meta.last_page > 1
                ? _c("b-pagination", {
                    staticClass: "my-1",
                    attrs: {
                      "per-page": _vm.meta.per_page,
                      "total-rows": _vm.meta.total,
                      align: "center",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.getOrganizations()
                      },
                    },
                    model: {
                      value: _vm.meta.current_page,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "current_page", $$v)
                      },
                      expression: "meta.current_page",
                    },
                  })
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "my-2" },
                _vm._l(_vm.organizations, function (organization) {
                  return _c("organization-list-card", {
                    key: organization.id,
                    attrs: { organization: organization },
                  })
                }),
                1
              ),
              _vm.organizations && _vm.meta.total > 0 && _vm.meta.last_page > 1
                ? _c("b-pagination", {
                    staticClass: "my-1",
                    attrs: {
                      "per-page": _vm.meta.per_page,
                      "total-rows": _vm.meta.total,
                      align: "center",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.getOrganizations()
                      },
                    },
                    model: {
                      value: _vm.meta.current_page,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "current_page", $$v)
                      },
                      expression: "meta.current_page",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }