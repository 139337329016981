<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <h3 class="grey-color mb-3 main_text font-weight-bold">Контракты 44-ФЗ</h3>
                <ul class="tabs">
                    <li v-for="(tab, index) in tabs" :key="'key_' + index">
                        <router-link :to="{ name: 'ProviderContractsCabinet', params: { cat: tab.path } }" exact>{{ tab.title }}</router-link>
                    </li>
                </ul>
            </b-col>
        </b-row>

        <b-row class="mb-2 cabinet_filter">
            <filter-new-multiple-select v-model="filter.status_id" :options="forFilters.statuses" label="Статус закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите статус" />
            <filter-new-text v-model="filter.reg_number" label="Рег.номер закупки" md="6" placeholder="Введите рег.номер закупки" />
            <filter-new-multiple-select v-model="filter.purchase_categories" :options="forFilters.purchaseCategories" label="Предмет закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите предметы закупки" />
            <filter-new-text v-model="filter.customer" label="Заказчик" md="6" placeholder="Введите название организации или ИНН" />

            <b-col class="my-2 text-right" lg="12" md="12">
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-green" @click.prevent="getContracts(true)">НАЙТИ</b-btn>
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-outline-secondary" @click.prevent="cleanFilters">СБРОС</b-btn>
            </b-col>
        </b-row>

        <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="getContracts()"></b-pagination>

        <template v-for="purchase in contracts">
            <out-system-contract v-if="purchase.is_external" :key="purchase.id" :purchase="purchase"></out-system-contract>
            <provider-contracts v-else :key="purchase.id" :purchase="purchase"></provider-contracts>
        </template>
        <empty-request v-if="!contracts.length && !loading"></empty-request>

        <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="getContracts()"></b-pagination>
    </div>
</template>

<script>
import FilterNewMultipleSelect from '@/components/common/filter-components/filter-new-multiple-select.vue';
import FilterNewText from '@/components/common/filter-components/filter-new-text.vue';
import CabinetPurchasesService from '@/services/api/cabinet/provider/purchases';
import ProviderContracts from '@/components/purchases/PurchaseStatusCard/PurchaseCustomerCabinet.vue';
import OutSystemContract from '@/components/purchases/outAtmo/PurchaseCardForCabinet.vue';

export default {
    name: 'Contracts',
    components: {
        FilterNewText,
        FilterNewMultipleSelect,
        ProviderContracts,
        OutSystemContract
    },
    data() {
        return {
            tabs: [
                { title: 'Все закупки', path: 'all' },
                { title: 'Закупки в ИС', path: 'in_atmo' },
                { title: 'Внесистемные закупки', path: 'outside_atmo' }
            ],
            contracts: [],
            loading: false,
            filter: {
                status_id: [],
                purchase_categories: [],
                reg_number: '',
                customer: ''
            },
            forFilters: {
                purchaseCategories: [],
                statuses: [
                    { name: 'Договор заключен', id: 3 },
                    { name: 'Договор исполнен', id: 4 },
                    { name: 'Договор не исполнен', id: 5 },
                    { name: 'Договор расторгнут', id: 6 }
                ]
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 10
            }
        };
    },
    async mounted() {
        await this.fillFilters();
        await this.getContracts();
    },
    watch: {
        $route: 'applyFilters'
    },
    methods: {
        async fillFilters() {
            this.forFilters.purchaseCategories = this.$globalDictionaries.deliverableGroups;
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getContracts();
        },
        getFilters(page = true) {
            return {
                filter: { ...this.filter },
                param: this.$route.params.cat,
                page: page ? this.meta.current_page : 1
            };
        },
        async getContracts(page = true, loading = true) {
            this.loading = loading;
            const contractsData = await CabinetPurchasesService.getContractsList(this.getFilters(page));
            this.contracts = contractsData.data.data;
            this.meta = contractsData.data.meta;
            this.$scrollToTop();
            this.loading = false;
        },
        cleanFilters() {
            this.filter.status_id.splice(0);
            this.filter.reg_number = '';
            this.filter.customer = '';
            this.filter.purchase_categories.splice(0);
            this.applyFilters();
        }
    }
};
</script>
<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}

.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    //border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.tabs li .router-link-active {
    color: var(--green-color) !important;
    border-bottom: 2px solid var(--green-color) !important;
}
</style>
