<template>
    <b-container>
        <b-row>
            <b-col>
                <p class="mt-4">
                    <span class="text-uppercase fw-600 grey-color text-decoration-none fs-14">44-ФЗ </span>
                </p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'notices.fl44', query: { status: 'accepting' } }">Извещения</router-link>
                    </li>
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'contracts.grouped', params: { group: 'fl44' } }">Контракты</router-link>
                    </li>
                </ul>
                <p class="mt-4">
                    <span class="text-uppercase fw-600 grey-color text-decoration-none fs-14">223-ФЗ </span>
                </p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'notices.fl223', query: { status: 'accepting' } }">Извещения</router-link>
                    </li>
                </ul>
                <p class="mt-4">
                    <span class="text-uppercase fw-600 grey-color text-decoration-none fs-14">КОММЕРЧЕСКИЕ </span>
                </p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'notices.commercial', query: { status: 'accepting' } }">Извещения</router-link>
                    </li>
                </ul>
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <router-link :to="'/organizations'" class="text-uppercase fw-600 grey-color text-decoration-none fs-14">ЗАКАЗЧИКИ И ПОСТАВЩИКИ</router-link>
                </p>
                <p class="mt-4">
                    <router-link :to="'/quotation-requests'" class="text-uppercase fw-600 grey-color text-decoration-none fs-14">Запросы КП</router-link>
                </p>
                <p class="mt-4">
                    <router-link :to="{ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }" class="text-uppercase fw-600 grey-color text-decoration-none fs-14">Импортозамещение </router-link>
                </p>
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4" v-if="$links.marketplace_link">
                    <!-- Add .atmo-link-off class to switch off link in themes  -->
                    <a :href="$links.marketplace_link" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14" target="_blank"> БАШМАРКЕТ </a>
                </p>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <!-- Add .atmo-link-off class to switch off link in themes  -->
                    <a :href="$links.info_materials" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14" target="_blank"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                </p>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <!-- Add .atmo-link-off class to switch off link in themes  -->
                    <a :href="$links.legal_documents" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14" target="_blank"> Правовые документы </a>
                </p>
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color text-decoration-none fs-14 mt-4">Режим работы</p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <p class="grey-color fs-14">9:00 до 18:00 (по времени г. {{ city }})</p>
                    </li>
                </ul>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { __LINKS } from '@/const';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    mounted() {
        let _this = this;
        document.getElementById('full-menu').addEventListener('click', function (e) {
            if (e.target.tagName.toLowerCase() === 'a') {
                _this.$emit('hide-menu');
            }
        });
    },
    computed: {
        city() {
            return Config.get('theme.variables.view.city');
        },
        ...mapGetters({
            informationSections: 'getInformation'
        }),
        links() {
            return __LINKS;
        }
    }
};
</script>

<style scoped>
.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}

.router-link-active {
    color: var(--green-color) !important;
}

.full_menu-feed:hover {
    color: var(--green-color);
}
</style>
