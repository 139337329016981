var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "8" } }, [
            _c("h3", { staticClass: "grey-color main_text font-weight-bold" }, [
              _vm._v("Запросы на добавление позиций КТРУ"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "4" } },
            [
              _c(
                "b-btn",
                {
                  attrs: {
                    to: { name: "KtruRequestsCreate" },
                    size: "sm",
                    variant: "custom-green",
                  },
                },
                [
                  _c("b-icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { "aria-hidden": "true", icon: "plus-circle" },
                  }),
                  _vm._v(" Сформировать запрос "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "my-3" },
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    href: _vm.$links.purchase_categories,
                    size: "sm",
                    variant: "custom-danger",
                  },
                },
                [_vm._v("Категории предметов закупок")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    href: _vm.$links.manual_ktru_requests_add,
                    size: "sm",
                    variant: "custom-danger",
                  },
                },
                [_vm._v("Инструкция по добавлению позиции КТРУ")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    href: _vm.$links.ktru_requests_video_manual,
                    size: "sm",
                    variant: "custom-danger",
                  },
                },
                [
                  _c("b-icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { "aria-hidden": "true", icon: "youtube" },
                  }),
                  _vm._v(" Видеоинструкция "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          !_vm.items.length
            ? _c("b-col", { staticClass: "text-center mt-5" }, [
                _c("img", {
                  attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
                }),
                _c("p", { staticClass: "fs-14 grey-color my-4" }, [
                  _vm._v(
                    "В данный момент запросы на добавление позиций КТРУ отсутствуют"
                  ),
                ]),
              ])
            : _c(
                "b-col",
                { staticClass: "mb-3" },
                [
                  _vm.items && _vm.meta.total > 0 && _vm.meta.last_page > 1
                    ? _c("b-pagination", {
                        staticClass: "my-2",
                        attrs: {
                          "per-page": _vm.meta.per_page,
                          "total-rows": _vm.meta.total,
                          align: "center",
                          "aria-controls": "my-table",
                        },
                        on: { input: _vm.fillData },
                        model: {
                          value: _vm.meta.current_page,
                          callback: function ($$v) {
                            _vm.$set(_vm.meta, "current_page", $$v)
                          },
                          expression: "meta.current_page",
                        },
                      })
                    : _vm._e(),
                  _c("b-table", {
                    staticClass: "table-atmo",
                    attrs: {
                      id: "my-table",
                      fields: _vm.fields,
                      items: _vm.items,
                      small: "",
                      hover: "",
                      responsive: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "row-details",
                        fn: function (data) {
                          return [
                            data.item.description
                              ? _c("p", [
                                  _c("b", [_vm._v("Описание:")]),
                                  _vm._v(" " + _vm._s(data.item.description)),
                                ])
                              : _vm._e(),
                            data.item.specs && data.item.specs.length > 0
                              ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                              : _vm._e(),
                            data.item.specs && data.item.specs.length > 0
                              ? _c(
                                  "ul",
                                  _vm._l(data.item.specs, function (spec, key) {
                                    return _c("li", { key: "spec" + key }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-weight": "900" },
                                        },
                                        [_vm._v(_vm._s(spec.name))]
                                      ),
                                      _vm._v(": " + _vm._s(spec.value) + " "),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "cell(name)",
                        fn: function (data) {
                          return [
                            _c("action-button-small", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    (data.detailsShowing
                                      ? "Скрыть"
                                      : "Показать") + " характеристики",
                                  expression:
                                    "(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              attrs: {
                                icon: data.detailsShowing ? "eye-slash" : "eye",
                              },
                              on: { click: data.toggleDetails },
                            }),
                            _vm._v(" " + _vm._s(data.item.name) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(okei)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.okei_local_symbol) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(country)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(data.item.country_of_origin_name) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(status)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _c("b", [_vm._v(_vm._s(data.item.status.title))]),
                            ]),
                            data.item.status.id === "draft" ||
                            data.item.status.id === "rejected"
                              ? [
                                  data.item.status.id === "rejected"
                                    ? _c("div", { staticClass: "mt-1" }, [
                                        _c("b", [_vm._v("Комментарий: ")]),
                                        _vm._v(_vm._s(data.item.comment)),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "btn-card-action btn-card-action-light mt-1",
                                      attrs: {
                                        to: {
                                          name: "KtruRequestsEdit",
                                          params: { id: data.item.id },
                                        },
                                      },
                                    },
                                    [_vm._v("Редактировать")]
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "btn-card-action btn-card-action-danger mt-1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteRequest(data.item.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Удалить")]
                                  ),
                                ]
                              : data.item.status.id === "accepted"
                              ? [
                                  _c("div", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      " Рег.номер созданной позиции КТРУ - "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(data.item.ktru_item_reg_number)
                                      ),
                                    ]),
                                  ]),
                                  data.item.allow_add_to_favorite
                                    ? _c(
                                        "b-btn",
                                        {
                                          staticClass:
                                            "btn-card-action btn-card-action-green mt-1",
                                          on: {
                                            click: function ($event) {
                                              _vm.$api.cabinet.ktru.ktruFavorites
                                                .addKtruItem({
                                                  ktru_item_id:
                                                    data.item.ktru_item_id,
                                                })
                                                .then(
                                                  () =>
                                                    (data.item.allow_add_to_favorite = false)
                                                )
                                            },
                                          },
                                        },
                                        [_vm._v("Добавить в избранное")]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.items && _vm.meta.total > 0 && _vm.meta.last_page > 1
                    ? _c("b-pagination", {
                        staticClass: "my-2",
                        attrs: {
                          "per-page": _vm.meta.per_page,
                          "total-rows": _vm.meta.total,
                          align: "center",
                          "aria-controls": "my-table",
                        },
                        on: { input: _vm.fillData },
                        model: {
                          value: _vm.meta.current_page,
                          callback: function ($$v) {
                            _vm.$set(_vm.meta, "current_page", $$v)
                          },
                          expression: "meta.current_page",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }