var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-between" },
            [
              _c("text-header-new", [_vm._v("Коммерческие предложения")]),
              _c(
                "ul",
                { staticClass: "tabs" },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    "li",
                    { key: "key_" + index },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "QuotationRequestsOffersList",
                              params: { cat: tab.path },
                            },
                            exact: "",
                          },
                        },
                        [_vm._v(_vm._s(tab.title))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.quotations && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: { input: _vm.fillData },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
      _vm._l(_vm.quotations, function (quotation) {
        return [
          quotation.status.id === "active"
            ? _c("quotation-card-open", {
                key: quotation.id,
                attrs: { quotation: quotation },
              })
            : _vm._e(),
          quotation.status.id === "inactive"
            ? _c("quotation-card-closed", {
                key: quotation.id,
                attrs: { quotation: quotation },
              })
            : _vm._e(),
        ]
      }),
      _vm.quotations && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: { input: _vm.fillData },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _c("div", { staticClass: "text-center mt-5" }, [
            _c("img", {
              attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
            }),
            _c("p", { staticClass: "fs-14 grey-color my-4" }, [
              _vm._v("По вашему запросу ничего не найдено"),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }