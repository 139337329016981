<template>
    <b-container class="mt-4">
        <b-row>
            <b-col>
                <div class="purchase_part">
                    <div class="atmo-override-color purchase_part-header" :class="cardCssClass">
                        <b-col>
                            <p class="purchase_part-header_number">
                                <router-link class="white-system_link-style fw-600" :to="{ name: 'OrderInfo', params: { order: purchase.id } }" exact target="_blank">ДОГОВОР № {{ purchase.reg_number }} </router-link>
                            </p>
                        </b-col>
                        <b-col cols="auto">
                            <p class="purchase_part-header_label fw-600">
                                {{ purchase.status_name }}
                                <img v-if="purchase.status_id === 6 || purchase.status_id === 5 || purchase.status_id === 10" class="ml-2" src="/images/white_times.svg" alt="" />
                                <img v-if="purchase.status_id === 4" class="ml-2" src="/images/file_check.svg" alt="" />
                                <img v-if="purchase.status_id === 3" class="ml-2" src="/images/white_contract.svg" alt="" />
                            </p>
                        </b-col>
                    </div>
                    <div class="purchase_part-subheader">
                        <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                            <span class="fs-11 green-color"
                                >НЦК: <span class="fw-600">{{ $formatPrice(purchase.start_price) }} руб.</span></span
                            >
                        </b-col>
                        <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                            <span class="fs-11 green-color"
                                >Предложений: <span class="fw-600">{{ purchase.count_offers }}</span></span
                            >
                        </b-col>
                        <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                            <span class="fs-11 green-color"
                                >Лучшее: <span class="fw-600">{{ purchase.min_offer ? $formatPrice(purchase.min_offer) + ' руб.' : '-' }} </span></span
                            >
                        </b-col>
                        <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                            <span class="fs-11 green-color"
                                >Снижение: <span class="fw-600">{{ purchase.discount_percentage }} %</span></span
                            >
                        </b-col>
                    </div>
                    <div class="purchase_part-body row">
                        <b-col md="3" class="purchase_part-body_col">
                            <div>
                                <div class="my-2" v-if="purchase.status_id === 3 || purchase.status_id === 4 || purchase.status_id === 5 || purchase.status_id === 6">
                                    <p class="fs-12 m-0 grey-color">Договор заключен:</p>
                                    <p class="fs-12 m-0 grey-color fw-600 time_to_end">
                                        {{ getDateTimeFormat(purchase.contract_date) }}
                                    </p>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="3" class="purchase_part-body_col">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                                    <p class="fs-12 grey-color fw-600">
                                        {{ purchase.purchase_name }}
                                    </p>
                                </div>
                                <div class="my-2 d-flex flex-column">
                                    <p class="fs-12 grey-color m-0">Заказчик:</p>
                                    <router-link class="system_link-style fw-600 text-uppercase fs-12" :to="'/organizations/' + purchase.customer_id" target="_blank">
                                        {{ purchase.customer_name }}
                                    </router-link>
                                </div>
                                <div class="my-2 d-flex flex-column">
                                    <p class="fs-12 grey-color m-0">Поставщик:</p>
                                    <router-link v-if="purchase.provider_id" class="system_link-style fw-600 text-uppercase fs-12" :to="'/organizations/' + purchase.provider_id" target="_blank">
                                        {{ purchase.provider_name }}
                                    </router-link>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="3" class="purchase_part-body_col hidden-mobile">
                            <div>
                                <div class="my-2" v-if="!!purchase.planned_end_date">
                                    <p class="fs-12 m-0 grey-color">Планируемая дата исполнения:</p>
                                    <p class="fw-600 fs-12 m-0 grey-color">
                                        {{ getDateFormat(purchase.planned_end_date) }}
                                    </p>
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color">
                                        Количество позиций:
                                        <span class="fw-600">{{ purchase.items_count }}</span>
                                    </p>
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color" v-if="purchase.contract_price">
                                        Цена договора:
                                        <span class="fw-600">{{ $formatPrice(purchase.contract_price) }} руб.</span>
                                    </p>
                                </div>
                                <div v-if="purchase.delivery && purchase.delivery.length" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Место доставки или указание на самовывоз:</p>
                                    <p class="fs-12 m-0 grey-color fw-600" v-for="deliver in purchase.delivery" :key="deliver.id" v-bind="deliver">
                                        {{ deliver.address }}
                                    </p>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="3" class="purchase_part-body_col">
                            <div>
                                <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                    <span style="width: 30px">
                                        <img src="/images/search.svg" alt="search" />
                                    </span>
                                    <router-link class="system_link-style" :to="{ name: 'OrderInfo', params: { order: purchase.id } }" exact target="_blank">Информация о закупке</router-link>
                                </p>
                                <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                    <span style="width: 30px">
                                        <img src="/images/dynamic.svg" alt="check_label" />
                                    </span>
                                    <router-link class="system_link-style" :to="{ name: 'ProposalDynamics', params: { order: purchase.id } }" exact target="_blank">Динамика предложений</router-link>
                                </p>
                                <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                    <span style="width: 30px">
                                        <img src="/images/small_helmet.svg" alt="check_label" />
                                    </span>
                                    <router-link class="system_link-style" :to="{ name: 'FinalProtocol', params: { order: purchase.id } }" exact target="_blank">Итоговый протокол</router-link>
                                </p>
                                <p class="d-flex align-items-center green-color fs-12 fw-600 my-2" v-if="purchase && purchase.contract">
                                    <span style="width: 30px">
                                        <img src="/images/contract.svg" alt="check_label" />
                                    </span>
                                    <router-link class="system_link-style" :to="{ name: 'Contract', params: { order: purchase.id } }" exact target="_blank">Договор</router-link>
                                </p>
                                <p class="d-flex align-items-center green-color fs-12 fw-600 my-2" v-if="purchase && purchase.contract && [3, 4, 5, 6].includes(purchase.status_id)">
                                    <span style="width: 30px">
                                        <img src="/images/file_small.svg" alt="check_label" />
                                    </span>
                                    <router-link class="system_link-style" :to="{ name: 'PurchaseExecutionDocuments', params: { order: purchase.id } }" exact target="_blank">Исполнение договора</router-link>
                                </p>
                                <purchase-supporting-buttons :purchase="purchase" />
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import PurchaseSupportingButtons from '@/components/elements/PurchaseSupportingButtons';
export default {
    components: { PurchaseSupportingButtons },
    props: ['purchase'],
    data() {
        return {};
    },
    computed: {
        cardCssClass() {
            switch (this.purchase.status_id) {
                case 4:
                    return 'card-header-blue';
                case 5:
                case 6:
                    return 'card-header-grey';
                case 10:
                    return 'card-header-yellow';
                default:
                    return 'card-header-green';
            }
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
}
.card-header-grey {
    background-color: #959ba4 !important;
}
.card-header-green {
    background-color: #26b89a !important;
}
.card-header-blue {
    background-color: #40acb4 !important;
}
.card-header-yellow {
    background-color: #f6b33a !important;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
