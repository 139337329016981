<template>
    <div>
        <b-button :disabled="isDataSending" class="text-uppercase" style="height: 32px; line-height: 15px" variant="custom-green" @click="showExportModal = true">Выгрузить реестр договоров в .XLSX</b-button>

        <b-modal v-model="showExportModal" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Выгрузка реестра договоров в .XLSX" size="lg">
            <form-row-edit-date label="Дата заключения договора, С" :label-cols-sm="6" :label-cols-lg="6" v-model="exportRequest.from" :max-date="exportRequest.to" />
            <form-row-edit-date label="Дата заключения договора, По (включительно)" :label-cols-sm="6" :label-cols-lg="6" v-model="exportRequest.to" :min-date="exportRequest.from" />
            <form-row-select-multiple label="Типы закупок" :options="$globalDictionaries.orderTypes" label-field="title" v-model="exportRequest.order_types" />
            <form-row-edit-radio label="Типы договоров" v-model="exportRequest.type" :options="radioOptions" stacked />

            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="handleCancel">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase" variant="custom-green" @click="handleAccept">Выгрузить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CabinetCustomerPurchases from '@/services/api/cabinet/customer/purchases';
import FileDownload from 'js-file-download';
import FormRowEditRadio from '@/components/common/form-rows/form-row-edit-radio.vue';
import FormRowSelectMultiple from '@/components/common/form-rows/form-row-select-multiple.vue';

export default {
    name: 'export-contracts-button',
    components: { FormRowSelectMultiple, FormRowEditRadio },
    data() {
        return {
            showExportModal: false,
            exportRequest: {
                from: null,
                to: null,
                type: 0,
                order_types: []
            },
            isDataSending: false,
            radioOptions: [
                { text: 'Контракты', value: 1 },
                { text: 'Внесистемные закупки', value: 2 },
                { text: 'Все', value: 0 }
            ]
        };
    },
    methods: {
        handleAccept() {
            this.isDataSending = true;
            CabinetCustomerPurchases.getContractsXlsExport(this.exportRequest)
                .then((response) => {
                    FileDownload(response.data, 'Договора.xlsx');
                    this.handleCancel();
                })
                .catch(() => {
                    this.handleCancel();
                });
        },
        handleCancel() {
            this.showExportModal = false;
            this.exportRequest.from = null;
            this.exportRequest.to = null;
            this.exportRequest.type = 0;
            this.exportRequest.order_types.splice(0);
            this.isDataSending = false;
        }
    }
};
</script>

<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}
</style>
