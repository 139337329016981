var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "grey-color mb-3 main_text font-weight-bold" }, [
        _vm._v("Извещения 44-ФЗ"),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-2 cabinet_filter" },
        [
          _c("filter-new-text", {
            attrs: {
              label: "Рег.номер закупки",
              md: "6",
              placeholder: "Введите рег.номер закупки",
            },
            model: {
              value: _vm.filter.reg_number,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "reg_number", $$v)
              },
              expression: "filter.reg_number",
            },
          }),
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.forFilters.statuses,
              label: "Статус закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус",
            },
            model: {
              value: _vm.filter.status_id,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "status_id", $$v)
              },
              expression: "filter.status_id",
            },
          }),
          _c("filter-new-multiple-select", {
            attrs: {
              options: _vm.forFilters.purchaseCategories,
              label: "Предмет закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите предметы закупки",
            },
            model: {
              value: _vm.filter.purchase_categories,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "purchase_categories", $$v)
              },
              expression: "filter.purchase_categories",
            },
          }),
          _c(
            "b-col",
            { staticClass: "mb-1 mt-auto", attrs: { lg: "3", md: "3" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase w-100",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getPurchases(true)
                    },
                  },
                },
                [_vm._v("НАЙТИ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-1 mt-auto", attrs: { lg: "3", md: "3" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase w-100",
                  staticStyle: { height: "32px", "line-height": "15px" },
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cleanFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("СБРОС")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.purchases && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getPurchases()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
      _vm._l(_vm.purchases, function (purchase) {
        return _c(
          "div",
          { key: purchase.id },
          [
            purchase.is_external
              ? _c("out-atmo-purchase-card", {
                  attrs: { purchase: purchase },
                  on: { refresh: _vm.refresh },
                })
              : _c("customer-purchase-card", {
                  staticClass: "mb-3",
                  attrs: { purchase: purchase },
                  on: {
                    cancelHoldTime: _vm.cancelHoldTime,
                    cancelPublishing: _vm.cancelPurchasePublishing,
                    copy: _vm.copyPurchase,
                    onDelete: _vm.preDelete,
                    onPublish: _vm.prePublish,
                    prolong: _vm.prolongPurchase,
                    refresh: _vm.refresh,
                  },
                }),
          ],
          1
        )
      }),
      !_vm.purchases.length && !_vm.loading ? _c("empty-request") : _vm._e(),
      _vm.purchases && _vm.meta.total > 0 && _vm.meta.last_page > 1
        ? _c("b-pagination", {
            staticClass: "my-1",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getPurchases()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "delete-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Удалить закупку",
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _vm._v(
              "Вы действительно хотите удалить закупку " +
                _vm._s(_vm.number) +
                "?"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.showDeleteModal = false
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _vm._v("  "),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-danger" },
                  on: { click: _vm.deletePurchase },
                },
                [_vm._v("Удалить")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "publish-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Опубликовать закупку",
          },
          on: { hidden: _vm.clearEmails },
          model: {
            value: _vm.showPublishModal,
            callback: function ($$v) {
              _vm.showPublishModal = $$v
            },
            expression: "showPublishModal",
          },
        },
        [
          _c(
            "b-form-group",
            { staticClass: "w-100", attrs: { state: !!_vm.emails.length } },
            [
              _c("div", { staticClass: "d-block text-left mb-3" }, [
                _vm._v(
                  "Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к закупке."
                ),
              ]),
              _c(
                "multiselect",
                {
                  class: {
                    "is-valid": _vm.emails.length,
                    "is-invalid": !_vm.emails.length,
                  },
                  attrs: {
                    id: "org-select",
                    "close-on-select": false,
                    "clear-on-select": false,
                    "internal-search": false,
                    loading: _vm.isLoading,
                    multiple: true,
                    options: _vm.organizations,
                    "options-limit": 100,
                    "preserve-search": false,
                    taggable: true,
                    "deselect-label": "Отменить",
                    label: "name",
                    placeholder:
                      "Выберите организации / укажите адреса электронной почты",
                    "select-label": "Выбрать",
                    "selected-label": "Выбрано",
                    "tag-placeholder": "Добавить",
                    "track-by": "id",
                  },
                  on: { tag: _vm.addTag, "search-change": _vm.asyncFind },
                  model: {
                    value: _vm.emails,
                    callback: function ($$v) {
                      _vm.emails = $$v
                    },
                    expression: "emails",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [_vm._v("Нет данных.")]
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [
                      _vm._v(
                        "Записей, соответствующих вашему запросу, не найдено."
                      ),
                    ]
                  ),
                ]
              ),
              !_vm.emails.length
                ? [
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { "force-show": "" } },
                      [_vm._v("Поле обязательно для заполнения")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "w-100 text-",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.showPublishModal = false
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: !_vm.emails.length,
                        variant: "custom-green",
                      },
                      on: { click: _vm.publishPurchase },
                    },
                    [_vm._v("Опубликовать")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }