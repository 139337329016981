<template>
    <div>
        <b-row>
            <b-col md="8">
                <text-header-new>Избранное</text-header-new>
            </b-col>
            <b-col class="text-right" md="4">
                <b-btn size="sm" variant="custom-green" @click="showAddModal = true">
                    <b-icon aria-hidden="true" class="my-auto mr-2" icon="plus-circle" />
                    Добавить позицию КТРУ
                </b-btn>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!items.length" class="text-center mt-5">
                <img src="/images/empty_box.svg" alt="empty-data" />
                <p class="fs-14 grey-color my-4">В данный момент избранных позиций КТРУ нет</p>
            </b-col>
            <b-col v-else class="mb-3">
                <b-pagination v-if="items && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" aria-controls="my-table" class="my-2" @input="fillSelected" />
                <b-row class="mb-3">
                    <b-col>
                        <span
                            >Выбрано позиций <strong>{{ allSelected.length }}</strong> из <strong>{{ meta.total }}</strong></span
                        >
                    </b-col>
                    <b-col class="text-right">
                        <b-dropdown :disabled="!allowCreatePurchase" variant="custom-green" text="Создать на основе выбранных позиций" class="text-uppercase" right>
                            <b-dropdown-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" @click="createDraft('fl44')" class="text-normal-case">Закупку 44ФЗ</b-dropdown-item>
                            <b-dropdown-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" @click="createDraft('fl44_external')" class="text-normal-case">Внесистемную закупку 44ФЗ</b-dropdown-item>
                            <template v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL223)">
                                <template v-if="$store.state.organization.settings.fl223.allowed">
                                    <b-dropdown-item @click="createDraft('fl223')" class="text-normal-case">Закупку 223-ФЗ</b-dropdown-item>
                                </template>
                                <template v-else>
                                    <b-dropdown-item :id="'popover-create-fl223-item'" class="text-normal-case">Закупку 223-ФЗ</b-dropdown-item>

                                    <b-popover :target="'popover-create-fl223-item'" triggers="hover">
                                        <div class="fs-12">
                                            Для создания закупки 223-ФЗ требуется дополнительная информация. Пожалуйста перейдите в
                                            <router-link style="color: var(--green-color); text-decoration: underline" :to="{ name: 'CabinetFl223Settings' }" active-class="" exact-active-class="">настройки</router-link>.
                                        </div>
                                    </b-popover>
                                </template>
                            </template>
                            <b-dropdown-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_COMMERCIAL)" @click="createDraft('commercial')" class="text-normal-case">Коммерческую закупку</b-dropdown-item>
                        </b-dropdown>
                        <span style="top: -14px; z-index: 1" class="atmo-badge">Новое</span>
                    </b-col>
                </b-row>
                <b-table id="my-table" :busy="isLoading" :fields="fields" :items="items" class="table-atmo" ref="selectableTable" selectable select-mode="multi" @row-selected="onRowSelected" small hover responsive>
                    <template #head(selected)>
                        <template v-if="items.length === pageSelected.length">
                            <b-icon style="cursor: pointer" aria-hidden="true" icon="check2-square" @click="selectDeselectAllRows" />
                        </template>
                        <template v-else>
                            <b-icon style="cursor: pointer" aria-hidden="true" icon="square" @click="selectDeselectAllRows" />
                        </template>
                    </template>
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <b-icon aria-hidden="true" icon="check2-square" />
                        </template>
                        <template v-else>
                            <b-icon aria-hidden="true" icon="square" />
                        </template>
                    </template>
                    <template #row-details="data">
                        <p v-if="data.item.ktru_item.description"><b>Описание:</b> {{ data.item.ktru_item.description }}</p>
                        <p v-if="data.item.ktru_item.specs && data.item.ktru_item.specs.length > 0"><b>Характеристики:</b></p>
                        <ul v-if="data.item.ktru_item.specs && data.item.ktru_item.specs.length > 0">
                            <li v-for="(spec, key) in data.item.ktru_item.specs" :key="'spec' + key">
                                <span style="font-weight: 900">{{ spec.name }}</span
                                >: {{ spec.value }}
                            </li>
                        </ul>
                    </template>
                    <template #cell(reg_num)="data"> {{ data.item.ktru_item.reg_number }}<br />{{ data.item.ktru_item.okpd_code }} </template>
                    <template #cell(specs)="data"> <action-button-small v-b-popover.hover.top="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" /> {{ data.item.ktru_item.name }} </template>
                    <template #cell(okei)="data">
                        {{ data.item.ktru_item.okei_local_symbol }}
                    </template>
                    <template #cell(country)="data">
                        {{ data.item.ktru_item.country_of_origin_name }}
                    </template>
                    <template #cell(actions)="data">
                        <action-button-small icon="trash" @click="onDeleteItem(data.item.id)" />
                    </template>
                </b-table>
                <b-row>
                    <b-col>
                        <span
                            >Выбрано позиций <strong>{{ allSelected.length }}</strong> из <strong>{{ meta.total }}</strong></span
                        >
                    </b-col>
                    <b-col class="text-right">
                        <b-dropdown :disabled="!allowCreatePurchase" variant="custom-green" text="Создать на основе выбранных позиций" class="text-uppercase" right>
                            <b-dropdown-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" @click="createDraft('fl44')" class="text-normal-case">Закупку 44ФЗ</b-dropdown-item>
                            <b-dropdown-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" @click="createDraft('fl44_external')" class="text-normal-case">Внесистемную закупку 44ФЗ</b-dropdown-item>
                            <template v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL223)">
                                <template v-if="$store.state.organization.settings.fl223.allowed">
                                    <b-dropdown-item @click="createDraft('fl223')" class="text-normal-case">Закупку 223-ФЗ</b-dropdown-item>
                                </template>
                                <template v-else>
                                    <b-dropdown-item :id="'popover-create-fl223-item1'" class="text-normal-case">Закупку 223-ФЗ</b-dropdown-item>

                                    <b-popover :target="'popover-create-fl223-item1'" triggers="hover">
                                        <div class="fs-12">
                                            Для создания закупки 223-ФЗ требуется дополнительная информация. Пожалуйста перейдите в
                                            <router-link style="color: var(--green-color); text-decoration: underline" :to="{ name: 'CabinetFl223Settings' }" active-class="" exact-active-class="">настройки</router-link>.
                                        </div>
                                    </b-popover>
                                </template>
                            </template>
                            <b-dropdown-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_COMMERCIAL)" @click="createDraft('commercial')" class="text-normal-case">Коммерческую закупку</b-dropdown-item>
                        </b-dropdown>
                        <span style="top: -14px; z-index: 1" class="atmo-badge">Новое</span>
                    </b-col>
                </b-row>
                <b-pagination v-if="items && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" aria-controls="my-table" class="my-2" @input="fillSelected" />
            </b-col>
        </b-row>
        <add-favorite-modal :add-item="addItem" :show-modal="showAddModal" :on-close="onCloseModal" />
    </div>
</template>

<script>
import AddFavoriteModal from '@/components/cabinets/common/ktru/favorites/parts/add-favorite-modal.vue';

export default {
    name: 'KtruRequestsList',
    components: { AddFavoriteModal },
    data() {
        return {
            isLoading: true,
            showAddModal: false,
            fields: [
                { key: 'selected' },
                { key: 'reg_num', label: 'Рег.№ / ОКПД2', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '1px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap' },
                { key: 'okei', label: 'Ед.изм.', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '1px' } },
                { key: 'country', label: 'Страна происхождения', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '1px' } },
                { key: 'actions', label: '', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap', thStyle: { width: '1px' } }
            ],
            items: [],
            pageSelected: [],
            allSelected: [],
            isDisabledOnSelect: false,
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    async mounted() {
        await this.fillData();
        this.$scrollToTop();
    },
    methods: {
        selectDeselectAllRows() {
            this.items.length === this.pageSelected.length ? this.$refs.selectableTable.clearSelected() : this.$refs.selectableTable.selectAllRows();
        },
        onRowSelected(items) {
            if (!this.isDisabledOnSelect) {
                this.pageSelected = [];
                items.map((item) => this.pageSelected.push(item.id));
                this.items.map(function (item) {
                    if (item.id !== this.pageSelected.find((value) => value === item.id)) {
                        const index = this.allSelected.indexOf(item.id);
                        if (index >= 0) this.allSelected.splice(index, 1);
                    }
                }, this);
                this.allSelected = [...new Set(this.allSelected.concat(this.pageSelected))];
            }
        },
        async fillSelected() {
            this.isDisabledOnSelect = true;
            await this.fillData();
            this.items.map(function (item, index) {
                if (item.id === this.allSelected.find((value) => value === item.id)) {
                    this.$refs.selectableTable.selectRow(index);
                }
            }, this);
            this.isDisabledOnSelect = false;
        },
        async fillData() {
            try {
                this.isLoading = true;
                const response = await this.$api.cabinet.ktru.ktruFavorites.getList(this.meta.current_page);
                this.items = response.data;
                this.meta = response.meta;
            } catch {
                this.items = [];
            } finally {
                this.isLoading = false;
            }
        },
        async createDraft(type) {
            const formData = {
                selected_items: this.allSelected,
                purchase_type: type
            };
            const response = await this.$api.cabinet.ktru.ktruFavorites.createDraft(formData);
            let routerData;
            switch (type) {
                case 'fl44':
                    routerData = this.$router.resolve({ name: 'purchases.grouped.item.edit', params: { group: 'fl44', id: response.id } });
                    break;
                case 'fl44_external':
                    routerData = this.$router.resolve({ name: 'ExternalPurchaseCreate', params: { mode: 'edit_draft', id: response.id } });
                    break;
                case 'fl223':
                    routerData = this.$router.resolve({ name: 'Fl223PurchaseEdit', params: { id: response.id } });
                    break;
                case 'commercial':
                    routerData = this.$router.resolve({ name: 'CommercialPurchaseEdit', params: { id: response.id } });
                    break;
            }
            window.open(routerData.href, '_blank');
        },
        async addItem(id) {
            const formData = {
                ktru_item_id: id
            };
            try {
                this.isLoading = true;
                await this.$api.cabinet.ktru.ktruFavorites.addKtruItem(formData);
            } finally {
                await this.fillSelected();
            }
        },
        onCloseModal() {
            this.showAddModal = false;
        },
        async onDeleteItem(id) {
            this.isLoading = true;
            const index = this.allSelected.indexOf(id);
            if (index >= 0) this.allSelected.splice(index, 1);
            if (await this.$api.cabinet.ktru.ktruFavorites.deleteKtruItem(id)) await this.fillSelected();
            this.isLoading = false;
        }
    },
    computed: {
        allowCreatePurchase() {
            return this.allSelected.length > 0 && (this.orgRoleIsPermitted(this.$orgRoles.CUSTOMER_FL44) || this.orgRoleIsPermitted(this.$orgRoles.CUSTOMER_FL223) || this.orgRoleIsPermitted(this.$orgRoles.CUSTOMER_COMMERCIAL));
        }
    }
};
</script>
<style scoped>
.text-normal-case {
    text-transform: none;
}
</style>
