var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-5", attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              ref: "filter",
              staticClass: "w-100",
              attrs: { autocomplete: "off" },
              on: {
                focusin: function ($event) {
                  _vm.alert = false
                },
              },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { md: "8" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-52 fw-600 white-color mt-2 mb-4 less_font-size_mobile",
                                  },
                                  [_vm._v("Импортозамещение")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2 mb-4 text-right",
                                  attrs: { md: "4" },
                                },
                                [
                                  _vm.$store.getters.isLoggedIn
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-custom-white-orange text-uppercase",
                                          staticStyle: { "max-width": "210px" },
                                          attrs: {
                                            to: {
                                              name: "ImportSubstitutionRequestCreate",
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Создать запрос на импортозамещение "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.$store.getters.isLoggedIn
                                    ? _c(
                                        "b-btn",
                                        {
                                          staticClass: "fs-14 my-1 my-3",
                                          staticStyle: {
                                            color: "white !important",
                                          },
                                          attrs: {
                                            href: _vm.$links
                                              .import_substitution_request_manual,
                                            size: "lg",
                                            target: "_blank",
                                            variant: "danger",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mx-1",
                                            staticStyle: { height: "16px" },
                                            attrs: {
                                              alt: "documentation",
                                              src: "/images/youtube.svg",
                                            },
                                          }),
                                          _vm._v(
                                            " Интерактивное руководство по импортозамещению "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "filter" },
                            [
                              _c("filter-multiple-select", {
                                attrs: {
                                  options: _vm.forFilters.purchaseCategories,
                                  label: "Предмет запроса",
                                  lg: "6",
                                  md: "6",
                                  placeholder: "Выберите предметы запроса",
                                },
                                model: {
                                  value: _vm.filter.purchaseCategories,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter,
                                      "purchaseCategories",
                                      $$v
                                    )
                                  },
                                  expression: "filter.purchaseCategories",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Наименование ТРУ",
                                  lg: "6",
                                  md: "6",
                                  placeholder: "Укажите наименование ТРУ",
                                },
                                model: {
                                  value: _vm.filter.ktruName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "ktruName", $$v)
                                  },
                                  expression: "filter.ktruName",
                                },
                              }),
                              _vm.showFilters
                                ? [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { cols: "12", lg: "3", md: "6" },
                                      },
                                      [
                                        _c("date-time-picker", {
                                          staticClass: "filter_datepicker",
                                          attrs: {
                                            id: "filter_from_purchase_start",
                                            "no-label":
                                              !!_vm.filter.orderDateStart,
                                            "only-date": true,
                                            autoClose: "",
                                            buttonNowTranslation: "Сегодня",
                                            format: "YYYY-MM-DD",
                                            formatted: "DD.MM.YYYY",
                                            label: "Укажите дату проведения с",
                                          },
                                          model: {
                                            value: _vm.filter.orderDateStart,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "orderDateStart",
                                                $$v
                                              )
                                            },
                                            expression: "filter.orderDateStart",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-10 halfWhite-color",
                                          },
                                          [_vm._v("Дата проведения с...")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { cols: "12", lg: "3", md: "6" },
                                      },
                                      [
                                        _c("date-time-picker", {
                                          staticClass: "filter_datepicker",
                                          attrs: {
                                            id: "filter_to_purchase_start",
                                            "no-label":
                                              !!_vm.filter.orderDateFinish,
                                            "only-date": true,
                                            autoClose: "",
                                            buttonNowTranslation: "Сегодня",
                                            format: "YYYY-MM-DD",
                                            formatted: "DD.MM.YYYY",
                                            label: "Укажите дату проведения по",
                                          },
                                          model: {
                                            value: _vm.filter.orderDateFinish,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "orderDateFinish",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filter.orderDateFinish",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-10 halfWhite-color",
                                          },
                                          [_vm._v("Дата проведения по...")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "margin-top": "27px" } },
                            [
                              _c(
                                "b-col",
                                { staticClass: "flex_col-mobile" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: { variant: "custom-white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getImportSubstitutionRequests(
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Найти")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.showOtherFilter },
                                    },
                                    [_vm._v("Все фильтры")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.clearFilter },
                                    },
                                    [_vm._v(" Сбросить")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.copyUrlWithFilters },
                                    },
                                    [_vm._v("Скопировать ссылку на фильтр")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    !_vm.mobileMenu
                      ? _c(
                          "ul",
                          { staticClass: "category_menu" },
                          _vm._l(
                            _vm.importSubstitutionStatuses,
                            function (category, cIndex) {
                              return _c(
                                "li",
                                { key: "category_" + cIndex },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "ImportSubstitutionRequestsList",
                                          params: { path: category.path },
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(category.title) + " ")]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.mobileMenu
                      ? _c(
                          "div",
                          [
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: "purchase_category",
                                    expression: "'purchase_category'",
                                  },
                                ],
                                staticClass:
                                  "fs-14 fw-600 my-3 green-color d-flex justify-content-between",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.activeTab[0].title) + " "
                                ),
                                _c("i", {
                                  staticClass:
                                    "fa fs-14 fw-600 green-color d-flex align-items-center",
                                  class: {
                                    "fa-chevron-down": !_vm.mobileMenu_show,
                                    "fa-chevron-up": _vm.mobileMenu_show,
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                attrs: { id: "purchase_category" },
                                model: {
                                  value: _vm.mobileMenu_show,
                                  callback: function ($$v) {
                                    _vm.mobileMenu_show = $$v
                                  },
                                  expression: "mobileMenu_show",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "mobile_category_menu" },
                                  _vm._l(
                                    _vm.importSubstitutionStatuses,
                                    function (category, cIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: "category_" + cIndex,
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeCategory(
                                                category.path
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(category.title) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "greyBg",
          staticStyle: { "border-top": "2px solid lightgray" },
          attrs: { id: "content-block", fluid: "" },
        },
        [
          _vm.importSubstitutionRequests.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        "per-page": _vm.meta.per_page,
                        "total-rows": _vm.meta.total,
                        align: "center",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getImportSubstitutionRequests()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._l(
            _vm.importSubstitutionRequests,
            function (importSubstitutionRequest) {
              return _c(
                "b-container",
                {
                  key: importSubstitutionRequest.id,
                  staticStyle: { "margin-top": "29px" },
                },
                [
                  _c(
                    "b-row",
                    [
                      importSubstitutionRequest.status_id === 1
                        ? _c("import-substitution-card-open", {
                            attrs: {
                              importSubstitutionRequest:
                                importSubstitutionRequest,
                            },
                            on: {
                              reloadData: _vm.getImportSubstitutionRequests,
                            },
                          })
                        : _vm._e(),
                      importSubstitutionRequest.status_id === 2
                        ? _c("import-substitution-card-closed", {
                            attrs: {
                              importSubstitutionRequest:
                                importSubstitutionRequest,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }
          ),
          !_vm.loading && !_vm.importSubstitutionRequests.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _vm.importSubstitutionRequests.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      attrs: {
                        "per-page": _vm.meta.per_page,
                        "total-rows": _vm.meta.total,
                        align: "center",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getImportSubstitutionRequests()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c("input", {
        staticClass: "hidden",
        staticStyle: { display: "none" },
        attrs: { id: "myInput", type: "text", value: "" },
      }),
      _c(
        "router-link",
        {
          staticClass: "copy_link hidden",
          staticStyle: { display: "none" },
          attrs: {
            id: "copy_link",
            to: {
              name: "ImportSubstitutionRequestsList",
              params: { order: _vm.current_route },
              query: _vm.buildQueries(),
            },
            exact: "",
          },
        },
        [_vm._v(" Информация о закупке ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }