<template>
    <div style="background-color: #f5f5f7">
        <b-container v-if="!!organization" class="main_layer py-4" fluid>
            <b-container>
                <b-row>
                    <b-col class="d-flex align-items-start justify-content-between column-768">
                        <div class="d-flex align-items-center fs-32 fw-600 white-color main_text">{{ organization.short_name || organization.full_name }}</div>
                        <div class="d-flex info_block mt-3">
                            <div v-if="organization.is_isp" v-b-tooltip.hover="{ variant: 'custom-green', show: true }" class="lable" title="Данный поставщик является прямым производителем товаров, исполнителем услуг или подрядчиком работ">ИСП</div>
                            <div v-if="organization.is_smp" v-b-tooltip.hover="{ variant: 'custom-green' }" class="lable" title="Является субъектом малого предпринимательства">СМП</div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container>
            <b-row v-if="!!organization">
                <b-col class="pb-5">
                    <text-header>Сведения об организации</text-header>
                    <text-row :compact="true" :value="organization.inn" label="ИНН" />
                    <text-row :compact="true" v-if="organization.kpp" :value="organization.kpp" label="КПП" />
                    <text-row :compact="true" :value="organization.ogrn" label="ОГРН / ОГРНИП" />
                    <text-row :compact="true" :value="organization.short_name" label="Сокращенное наименование" />
                    <text-row :compact="true" :value="organization.full_name" label="Полное наименование" />
                    <text-row :compact="true" :value="organization.post_address" label="Почтовый адрес" />
                    <text-row :compact="true" :value="organization.fact_address" label="Фактический адрес" />
                    <text-row :compact="true" :value="organization.manager" label="Руководитель" />

                    <text-header>Контактная информация</text-header>
                    <text-row :compact="true" :value="organization.email" label="Адрес электронной почты" />
                    <text-row-phone :compact="true" :value="organization.phone" label="Телефон" />
                    <text-row :compact="true" :value="organization.contact_name" label="Контактное лицо" />

                    <template v-if="organization.is_provider">
                        <text-header>Статистика</text-header>
                        <b-row class="py-3 statistic_block mx-0">
                            <b-col class="px-4 py-1" md="4">
                                <div v-if="organization.rating" class="h-100 d-flex flex-column justify-content-between">
                                    <p class="text-uppercase grey-color fs-14 mb-4">РЕЙТИНГ ПОСТАВЩИКА</p>
                                    <div>
                                        <span :style="`color: ${ratingColor()}`" class="fs-32 fw-600">{{ $formatPrice(organization.rating) }}</span>
                                        <span class="fs-14 grey-color text-lowercase ml-2">{{ ratingText() }}</span>
                                    </div>
                                </div>
                            </b-col>
                            <b-col class="px-4 py-1" md="4">
                                <div class="h-100 d-flex flex-column justify-content-between align-items-start">
                                    <router-link class="text-uppercase grey-color fs-14 mb-4 cursor-pointer" tag="a" v-bind:to="{ name: 'contracts.grouped', params: { group: 'fl44' }, query: getQueries }"> КОЛИЧЕСТВО ЗАКЛЮЧЕННЫХ ДОГОВОРОВ </router-link>
                                    <div>
                                        <span class="fs-32 green-color fw-600">{{ organization.contracts_count || 0 }}</span>
                                        <span class="fs-14 grey-color text-lowercase ml-2">договоров</span>
                                    </div>
                                </div>
                            </b-col>
                            <b-col class="px-4 py-1" md="4">
                                <div class="h-100 d-flex flex-column justify-content-between align-items-start">
                                    <!--<p class="text-uppercase grey-color fs-14 mb-4">СУММА ЗАКЛЮЧЕННЫХ ДОГОВОРОВ</p>-->
                                    <router-link class="text-uppercase grey-color fs-14 mb-4 cursor-pointer" tag="a" v-bind:to="{ name: 'contracts.grouped', params: { group: 'fl44' }, query: getQueries }"> СУММА ЗАКЛЮЧЕННЫХ ДОГОВОРОВ </router-link>
                                    <div>
                                        <span class="fs-32 green-color fw-600">{{ $formatPrice(organization.contracts_sum) }}</span>
                                        <span class="fs-14 grey-color text-lowercase ml-2">руб.</span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'organization-show',
    metaInfo() {
        return {
            title: 'Организация ' + this.organization?.inn
        };
    },
    data() {
        return {
            organization: null
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        getQueries() {
            let queries = {};
            let filters = {};
            filters.provider = this.organization.inn;
            queries.filter = JSON.stringify(filters);
            return queries;
        },
        currentOrganization() {
            return this.$store.state.organization;
        }
    },
    methods: {
        async getData() {
            this.organization = await this.$api.organizations.getOrganization(this.$route.params.id);
        },
        parsePhone(text) {
            if (text) return text.replace(/(\d{1})(\d{3})/, '+$1 ($2) ');
            return '-';
        },
        ratingText() {
            let clientRating = this.organization.rating;
            let text = null;
            clientRating <= 2 ? (text = 'низкий') : clientRating > 2 && clientRating < 4 ? (text = 'средний') : (text = 'высокий');
            return text;
        },
        ratingColor() {
            let clientRating = this.organization.rating;
            let color = null;
            clientRating <= 2 ? (color = '#E75D4F') : clientRating > 2 && clientRating < 4 ? (color = '#F6B33A') : (color = '#26B89A');
            return color;
        }
    }
};
</script>

<style scoped>
.lable {
    font-size: 12px;
    font-weight: 600;
    background: #fff;
    border-radius: 4px;
    color: var(--green-color);
    padding: 1px 11px;
    margin-left: 11px;
    line-height: 1;
    display: flex;
    align-items: center;
}

.statistic_block {
    background-color: #fff;
    box-shadow: 0px 2px 4px #0000000a;
    border-radius: 4px;
}

.statistic_block div + div {
    border-left: 1px solid var(--grey-color);
}

.main_layer {
    background-image: url('/images/providers_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 50px;
}

@media screen and (max-width: 992px) {
    .main_text {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 768px) {
    .column-768 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .statistic_block div + div {
        border-top: 1px solid var(--grey-color);
        border-left: none;
    }

    .info_block {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 470px) {
    .main_text {
        font-size: 32px !important;
    }

    .purchase_part-header_label {
        white-space: nowrap;
    }
}

@media screen and (max-width: 470px) {
    .main_text {
        line-height: 1;
    }
}
</style>
