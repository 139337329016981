<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <text-header-new>Запросы КП</text-header-new>
                <div>
                    <b-btn variant="custom-green" style="background: transparent" :to="{ name: 'QuotationRequestCreate' }"> Сформировать запрос КП </b-btn>
                </div>
                <ul class="tabs">
                    <li v-for="(tab, index) in tabs" :key="'key_' + index">
                        <router-link :to="{ name: 'QuotationRequestsRequestsList', params: { cat: tab.path } }" exact>{{ tab.title }}</router-link>
                    </li>
                </ul>
            </b-col>
        </b-row>

        <b-pagination v-if="quotationRequests && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="fillData"></b-pagination>

        <template v-for="quotationRequest in quotationRequests">
            <quotation-request-card-open :quotationRequest="quotationRequest" v-if="quotationRequest.status.id === 'started'" :key="quotationRequest.id" @reloadData="fillData" />
            <quotation-request-card-closed :quotationRequest="quotationRequest" v-if="quotationRequest.status.id === 'completed'" :key="quotationRequest.id" />
        </template>

        <empty-request v-if="!quotationRequests.length && !loading"></empty-request>

        <b-pagination v-if="quotationRequests && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="fillData"></b-pagination>
    </div>
</template>

<script>
import QuotationRequestCardOpen from './parts/RequestCardOpen.vue';
import QuotationRequestCardClosed from './parts/RequestCardClosed.vue';

export default {
    name: 'CustomerQuotationRequestsList',
    components: {
        QuotationRequestCardOpen,
        QuotationRequestCardClosed
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Все запросы',
                    path: 'all'
                },
                {
                    title: 'Активные',
                    path: 'active'
                },
                {
                    title: 'Завершенные',
                    path: 'finished'
                }
            ],
            quotationRequests: [],
            loading: true,
            sortOrder: [{ field: 'created_at', direction: 'asc' }],
            meta: {
                current_page: 1,
                per_page: 5,
                total: 0
            }
        };
    },
    async mounted() {
        await this.fillData();
        this.$scrollToTop();
    },
    methods: {
        async fillData() {
            try {
                this.loading = true;
                const response = await this.$api.cabinet.quotationRequests.getRequestList(this.$route.params.cat, this.meta.current_page);
                this.quotationRequests = response.data;
                this.meta = response.meta;
            } catch {
                this.quotationRequests = [];
            } finally {
                this.loading = false;
            }
        },
        async routeFetchData() {
            this.meta.current_page = 1;
            await this.fillData();
            this.$scrollToTop();
        }
    },
    watch: {
        $route: 'routeFetchData'
    }
};
</script>

<style scoped>
.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    //border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.tabs li .router-link-active {
    color: var(--green-color) !important;
    border-bottom: 2px solid var(--green-color) !important;
}
</style>
