<template>
    <div>
        <b-row>
            <b-col md="8">
                <h3 class="grey-color main_text font-weight-bold">Отчеты по закупкам</h3>
            </b-col>
            <b-col class="text-right" md="4">
                <b-btn :href="$links.fl44_purchase_reports_manual" size="sm" variant="custom-danger" target="_blank">
                    <b-icon aria-hidden="true" class="my-auto mr-2" icon="play-circle" />
                    Инструкция
                </b-btn>
            </b-col>
        </b-row>

        <div class="table-responsive py-3">
            <b-pagination v-if="reports && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" @input="getReports()"></b-pagination>

            <b-table :busy="loading" :fields="fields" :items="reports" :per-page="meta.per_page" class="table-atmo" empty-text="Данные отсутствуют" show-empty>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Загрузка данных...</strong>
                    </div>
                </template>
                <template #cell(period)="row">
                    {{ `${row.item.quarter} квартал ${row.item.year}` }}
                    <template v-if="row.item.status.id !== 'new'">
                        <action-button-small :icon="!row.detailsShowing ? 'eye' : 'eye-slash'" :title="(!row.detailsShowing ? 'Показать' : 'Скрыть') + ' подробности'" @click="row.toggleDetails" />
                    </template>
                </template>
                <template #cell(sent_at)="row">
                    {{ row.item.sent_at ? getDateTimeFormat(row.item.sent_at) : '&mdash;' }}
                </template>
                <template #cell(sent_by_user)="row">
                    <template v-if="row.item.sent_by_user">{{ row.item.sent_by_user }}</template>
                    <template v-else>&mdash;</template>
                </template>
                <template #cell(status)="row">
                    <template v-if="row.item.can_be_filled">
                        <fl44-report-fill-button :fl44-purchase-report="row.item" @refresh="getReports()" />
                    </template>
                    <b-badge v-else :variant="statusBadgeVariant(row.item.status.id)" class="fs-14" pill>{{ row.item.status.title }}</b-badge>
                </template>

                <template #row-details="row">
                    <div v-if="row.item.status.id === 'rejected' || row.item.status.id === 'reject_requested'" class="mb-3">
                        <text-row label="Причина запроса аннулирования" :value="row.item.reject_request_reason" />
                    </div>
                    <div v-if="row.item.status.id === 'error'" class="mb-3 d-flex align-items-center justify-content-between">
                        <span class="text-danger">Указанные в отчете суммы не соответствуют информации, присутствующей в ИС.</span>
                        <fl44-report-delete-button :fl44-purchase-report="row.item" class="mx-2" @refresh="getReports()" />
                        <fl44-report-recalculate-button :fl44-purchase-report="row.item" class="ml-2" @refresh="getReports()" />
                    </div>
                    <div class="table-responsive">
                        <table class="table table-atmo">
                            <thead>
                                <tr>
                                    <th class="text-nowrap">Основание закупки</th>
                                    <th>Кол-во в ИС</th>
                                    <th>Сумма в ИС</th>
                                    <th>Кол-во в отчете</th>
                                    <th>Сумма в отчете</th>
                                    <th>Разница кол-во</th>
                                    <th>Разница сумма</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="detailItem in row.item.details" :key="detailItem.id">
                                    <td>{{ detailItem.order_type_name }}</td>
                                    <td class="text-nowrap">{{ row.item.status.id !== 'processing' ? detailItem.calc_purchases_count : '&mdash;' }}</td>
                                    <td class="text-right text-nowrap">{{ row.item.status.id !== 'processing' ? $formatPrice(detailItem.calc_purchases_sum) : '&mdash;' }}</td>
                                    <td class="text-nowrap">{{ detailItem.total_purchases_count }}</td>
                                    <td class="text-right text-nowrap">{{ $formatPrice(detailItem.total_purchases_sum) }}</td>
                                    <td :class="detailItem.is_error_count ? 'text-danger' : ''" class="text-nowrap">{{ row.item.status.id !== 'processing' ? detailItem.diff_purchases_count : '&mdash;' }}</td>
                                    <td :class="detailItem.is_error_sum ? 'text-danger' : ''" class="text-right text-nowrap">{{ row.item.status.id !== 'processing' ? $formatPrice(detailItem.diff_purchases_sum) : '&mdash;' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </b-table>

            <b-pagination v-if="reports && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" @input="getReports()"></b-pagination>
        </div>
    </div>
</template>

<script>
import { $api } from '@/services/backend/api';
import Fl44ReportFillButton from '@/components/cabinets/fl44/purchase-reports/fl44-report-fill-button.vue';
import Fl44ReportRecalculateButton from '@/components/cabinets/fl44/purchase-reports/fl44-report-recalculate-button.vue';
import Fl44ReportDeleteButton from '@/components/cabinets/fl44/purchase-reports/fl44-report-delete-button.vue';

export default {
    name: 'cabinet-fl44-purchase-reports',
    components: { Fl44ReportDeleteButton, Fl44ReportRecalculateButton, Fl44ReportFillButton },
    metaInfo: {
        title: 'Закупки 44ФЗ. Отчеты по закупкам'
    },
    data() {
        return {
            loading: false,
            reports: [],
            meta: {
                current_page: 1,
                total: 0,
                per_page: 15
            },
            fields: [
                { key: 'period', label: 'Период', tdClass: 'text-nowrap' },
                { key: 'version', label: '№ версии' },
                { key: 'sent_at', label: 'Дата и время отправки' },
                { key: 'sent_by_user', label: 'ФИО пользователя' },
                { key: 'status', label: 'Статус отчета' }
            ]
        };
    },
    async mounted() {
        await this.getReports();
    },
    methods: {
        getFilters(page = true) {
            return {
                page: page ? this.meta.current_page : 1
            };
        },
        async getReports(page = true, loading = true) {
            this.loading = loading;
            const response = await $api.personal.fl44PurchaseReports.getReportsList(this.getFilters(page));
            if (response) {
                ({ data: this.reports, meta: this.meta } = response);
                this.$scrollToTop();
            }
            this.loading = false;
        },
        refresh() {
            this.getReports();
        },
        statusBadgeVariant(status) {
            switch (status) {
                case 'new':
                case 'processing':
                case 'rejected':
                    return 'secondary';
                case 'error':
                    return 'danger';
                case 'accepted':
                    return 'success';
            }
        }
    }
};
</script>
