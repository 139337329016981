var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "mt-4" }, [
              _c(
                "span",
                {
                  staticClass:
                    "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                },
                [_vm._v("44-ФЗ ")]
              ),
            ]),
            _c("ul", { staticClass: "full_menu-list" }, [
              _c(
                "li",
                { staticClass: "list-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        exact: "",
                        to: {
                          name: "notices.fl44",
                          query: { status: "accepting" },
                        },
                      },
                    },
                    [_vm._v("Извещения")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "list-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        exact: "",
                        to: {
                          name: "contracts.grouped",
                          params: { group: "fl44" },
                        },
                      },
                    },
                    [_vm._v("Контракты")]
                  ),
                ],
                1
              ),
            ]),
            _c("p", { staticClass: "mt-4" }, [
              _c(
                "span",
                {
                  staticClass:
                    "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                },
                [_vm._v("223-ФЗ ")]
              ),
            ]),
            _c("ul", { staticClass: "full_menu-list" }, [
              _c(
                "li",
                { staticClass: "list-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        exact: "",
                        to: {
                          name: "notices.fl223",
                          query: { status: "accepting" },
                        },
                      },
                    },
                    [_vm._v("Извещения")]
                  ),
                ],
                1
              ),
            ]),
            _c("p", { staticClass: "mt-4" }, [
              _c(
                "span",
                {
                  staticClass:
                    "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                },
                [_vm._v("КОММЕРЧЕСКИЕ ")]
              ),
            ]),
            _c("ul", { staticClass: "full_menu-list" }, [
              _c(
                "li",
                { staticClass: "list-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        exact: "",
                        to: {
                          name: "notices.commercial",
                          query: { status: "accepting" },
                        },
                      },
                    },
                    [_vm._v("Извещения")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("b-col", [
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: "/organizations" },
                  },
                  [_vm._v("ЗАКАЗЧИКИ И ПОСТАВЩИКИ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-4" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: "/quotation-requests" },
                  },
                  [_vm._v("Запросы КП")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-4" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      to: {
                        name: "ImportSubstitutionRequestsList",
                        params: { path: "open" },
                      },
                    },
                  },
                  [_vm._v("Импортозамещение ")]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", [
            _vm.$links.marketplace_link
              ? _c(
                  "p",
                  {
                    staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                        attrs: {
                          href: _vm.$links.marketplace_link,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" БАШМАРКЕТ ")]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      href: _vm.$links.info_materials,
                      target: "_blank",
                    },
                  },
                  [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      href: _vm.$links.legal_documents,
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Правовые документы ")]
                ),
              ]
            ),
          ]),
          _c("b-col", [
            _c(
              "p",
              {
                staticClass:
                  "text-uppercase fw-600 grey-color text-decoration-none fs-14 mt-4",
              },
              [_vm._v("Режим работы")]
            ),
            _c("ul", { staticClass: "full_menu-list" }, [
              _c("li", { staticClass: "list-item" }, [
                _c("p", { staticClass: "grey-color fs-14" }, [
                  _vm._v(
                    "9:00 до 18:00 (по времени г. " + _vm._s(_vm.city) + ")"
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }